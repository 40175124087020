<template>
  <div>
    <el-menu :default-active="this.$route.path" mode="horizontal" text-color="#544646" active-text-color="#fb3434" @select="handleSelect">
      <el-menu-item v-for="(item, i) in navList" :index="item.path" :key="i">{{ item.title }}</el-menu-item>
      <!-- <el-menu-item class="kefu">联系客服</el-menu-item>
      <el-menu-item>供货商入住</el-menu-item> -->
      <!-- <el-menu-item @click="login">登录注册</el-menu-item>  -->
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        { path: '/index', title: '首页' },
        { path: '/goods', title: '礼品商城' },
        { path: '/account/mycenter', title: '个人中心' },
        { path: '/notice', title: '系统公告' },
        // { path: '/vipprice', title: '会员价格' },
        { path: '/join', title: '分站开通' }
        // { path: 'api', title: 'API接口' }
      ],
      disabled: true
    }
  },
  beforeCreate () {
    // console.log('code')
  },
  // computed: {
  //   // eslint-disable-next-line vue/return-in-computed-property
  //   navList1() {
  //     if (localStorage.getItem('code')) {
  //       return [
  //         { path: '/index', title: '首页' },
  //         { path: '/goods', title: '礼品商城' },
  //         { path: '/account/mycenter', title: '个人中心' },
  //         { path: '/notice', title: '系统公告' }
  //       ]
  //     } else {
  //       return [
  //         { path: '/index', title: '首页' },
  //         { path: '/goods', title: '礼品商城' },
  //         { path: '/account/mycenter', title: '个人中心' },
  //         { path: '/notice', title: '系统公告' },
  //         { path: '/join', title: '分站开通' },
  //         { path: 'api', title: 'API接口' }
  //       ]
  //     }
  //   }
  // },
  // directives: {
  //   focus: {
  //     inserted() {
  //       this.handleShow()
  //       console.log('触发了', '66--------')
  //     }
  //   }
  // },

  methods: {
    login () {
      this.$router.push('/login')
    },
    handleSelect (index) {
      switch (index) {
        case '/index':
          this.$router.push('/index')
          break
        case '/join':
          this.$router.push('/join')
          break
        case '/notice':
          this.$router.push('/notice')
          break
        case '/vipprice':
          this.$router.push('/vipprice')
          break
        case 'api':
          window.open(this.$store.getters.getSiteInfo.api_service, '_blank')
          break
        case '/goods':
          this.$router.push('/goods')
          break
        case '/account/mycenter':
          this.$router.push('/account/mycenter')
          break
        case '/':
          this.$router.push('/')
          break
      }
    }
  }
}
</script>

<style scoped src="styles/components/navbarmenu.css">
</style>
