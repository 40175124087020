<template>
  <div class="user">
    <!-- 登陆 -->
    <div v-if="userInfo" class="yes-user" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <div class="avatar">
        <!-- <el-avatar :size="24" :src="userInfo.avatar" @error="errorHandler" >
          <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
        </el-avatar> -->
        <el-avatar  v-if="userInfo" class="avatar-user"  :size="72" :src="userInfo.avatar" > </el-avatar>
        <el-avatar class="avatar-user" v-else :size="72" >
          <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
        </el-avatar>
        <span class="user-name">{{userInfo.nickname}}</span>
      </div>
      <div v-show="showMore" class="show-more">
        <div @click="toPurchase">礼品采购</div>
        <div @click="logout">退出登录</div>
      </div>
    </div>
     <!-- 未登录 -->
    <div v-else class="no-user">
      <div class="dl" @click="toLogin">登陆</div>
      <div class="mfzc" @click="toRegister">免费注册</div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  data () {
    return {
      // userInfo: JSON.parse(localStorage.getItem('userInfo')),
      showMore: false
    }
  },
  computed: {
    // 获取用户信息
    userInfo () {
      return this.$store.getters.getUserInfo
    }
  },
  methods: {

    toLogin () {
      this.$router.push('login')
    },
    toRegister () {
      this.$router.push('register')
    },
    refresh () {
      this.reload()
    },
    // 退出登录
    logout () {
      this.$confirm('真的要注销登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.refresh()
        // ,退出登录
        // this.$store.dispatch('logout')
        // localStorage.removeItem('userInfo')
        // localStorage.removeItem('token')
        // this.$router.go(0)
        localStorage.clear()// 清除缓存
        setTimeout(() => { location.reload() }, 100)
        // this.$router.push('/login')
      }).catch(() => {
      })
    },
    // 跳转至礼品采购
    toPurchase () {
      this.$router.push('/orderAdmin/purchase')
    },
    // 鼠标移入
    mouseOver () {
      this.showMore = true
    },
    // 鼠标移出
    mouseLeave () {
      this.showMore = false
    }
  }
}
</script>

<style scoped src="styles/components/navbaruser.css">
</style>
